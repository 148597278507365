import * as React from 'react';
import classNames from 'classnames';
import { MatrixGallerySkinProps } from '../MatrixGallery.types';
import { TestIds, translations } from '../constants';

const emptyFn = () => {};

const MatrixGallery: React.FC<MatrixGallerySkinProps> = props => {
  const {
    id,
    items,
    rows,
    columns,
    extraRows,
    showMoreLabel,
    imageOnClickAction,
    openImageZoom = emptyFn,
    skinsStyle,
    skinsItemStyle,
    translate,
    MatrixGalleryItemComponent,
    isFitMode,
    onItemClicked,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const numberOfInitialItems = rows * columns;
  const [numberOfExtraRows, setNumberOfExtraRows] = React.useState(0);
  const [itemToFocusIndex, setItemToFocusIndex] = React.useState(-1);
  const numberOfItemsToShow = Math.min(
    numberOfInitialItems + numberOfExtraRows * columns,
    items.length,
  );
  const columnClass = `columns-${columns}`;
  const itemsToShow = items.slice(0, numberOfItemsToShow).map((item, index) => {
    const itemClickHandler = () => {
      onItemClicked?.({
        itemIndex: index,
        type: 'itemClicked',
      });
    };

    return (
      <MatrixGalleryItemComponent
        key={`item-${index}`}
        extraClassNames={{ root: skinsStyle.item }}
        skinsStyle={skinsItemStyle}
        imageOnClickAction={imageOnClickAction}
        openImageZoom={(dataId: string) => openImageZoom(dataId, id)}
        itemClickHandler={itemClickHandler}
        translate={translate}
        isFocused={index === itemToFocusIndex}
        isFitMode={isFitMode}
        {...item}
      />
    );
  });

  const showMore = React.useCallback(() => {
    setItemToFocusIndex(numberOfItemsToShow);
    setNumberOfExtraRows(numberOfExtraRows + extraRows);
  }, [
    numberOfExtraRows,
    extraRows,
    setNumberOfExtraRows,
    setItemToFocusIndex,
    numberOfItemsToShow,
  ]);

  const canShowMore = numberOfItemsToShow < items.length;

  return (
    <div
      id={id}
      className={skinsStyle.root}
      role="region"
      aria-label={translate!(
        translations.ARIA_LABEL_NAMESPACE,
        translations.MATRIX_GALLERY_ARIA_LABEL_KEY,
        translations.MATRIX_GALLERY_ARIA_LABEL_DEFAULT,
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classNames(
          skinsStyle.itemsContainer,
          skinsStyle[columnClass],
        )}
        data-testid={TestIds.itemsContainer}
      >
        {itemsToShow}
      </div>
      <div className={skinsStyle.showMoreContainer}>
        {canShowMore && (
          <button
            data-testid={TestIds.showMoreButton}
            className={skinsStyle.showMore}
            onClick={showMore}
          >
            {showMoreLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default MatrixGallery;
